import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div className="hero bg-indigo-800">
      <h1 className="font-serif text-4xl md:pt-10 pb-40 md:text-7xl py-10 text-4xl text-center text-white">404 <span className="text-blue-300">Oh no!</span></h1>
    </div>
    <div className="bg-gray-100 pb-56">
      <div className="max-w-screen-lg pt-32 md:pb-24 mx-auto px-8 py-10">
        <img 
          alt="Clown fish in sea anemone"
          className="-mt-64 mx-auto" 
          src="/img/404nemo.jpg"
          style={{maxHeight: '20rem'}}
        />
        <p className="md:py-8 md:text-3xl py-6 text-center text-indigo-900 text-lg">Nemo could not be found... try again</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
